import { pipe, tap } from "wonka";
import { Exchange, Operation } from "urql";
import { print } from "graphql";

interface QueryTracker {
  timestamps: number[];
  queryString: string;
  variables: Operation["variables"];
}

// Configuration
const TIME_WINDOW = 120000;
const FREQUENCY_THRESHOLD = 30;
const MAX_TRACKED_INSTANCES = 20;

// Sometimes we might have infinite query loops in our app
// which might be overlooked by React. This exchange is to identify those
// cases where a certain query is triggered every n seconds or so for whatever
// reason, so that we can identify the root cause
export const infiniteLoopExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    // This will live as long as the client instance & cache is alive, i.e
    // If the page is refreshed, it would be reset
    const queryLog = new Map<string, QueryTracker>();

    return pipe(
      ops$,
      tap((operation: Operation) => {
        if (operation.kind === "query") {
          const now = Date.now();
          const queryString = print(operation.query);
          const queryHash = `${queryString}-${JSON.stringify(
            operation.variables
          )}`;

          // Try to get the hash for the current query (w/ variables)
          const tracker = queryLog.get(queryHash) || {
            timestamps: [],
            queryString,
            variables: operation.variables,
          };

          // Push the current timestamp for that entry
          tracker.timestamps.push(now);

          // Only store MAX_TRACKED_INSTANCES per query, and remove the rest
          // This is to eliminate storing a perpetually growing list
          if (tracker.timestamps.length > MAX_TRACKED_INSTANCES) {
            tracker.timestamps = tracker.timestamps.slice(
              -MAX_TRACKED_INSTANCES
            );
          }

          const cutoffTime = now - TIME_WINDOW;

          // Get the no of instances of the query triggered in the last TIME_WINDOW interval
          tracker.timestamps = tracker.timestamps.filter(
            (time) => time > cutoffTime
          );

          // If it exceeds FREQUENCY_THRESHOLD, report to console
          if (tracker.timestamps.length >= FREQUENCY_THRESHOLD) {
            const timeSpan = now - tracker.timestamps[0];
            console.error(
              `Potential infinite loop detected!\n` +
                `Query executed ${tracker.timestamps.length} times in ${
                  timeSpan / 1000
                } seconds\n` +
                `Query: ${queryString}\n` +
                `Variables: ${JSON.stringify(operation.variables)}\n`
            );

            queryLog.delete(queryHash);
            return;
          }

          queryLog.set(queryHash, tracker);
        }
      }),
      forward
    );
  };
