export const neutral = {
  vividlyBlack: "#000000",
  vividlyWhite: "#FFFFFF",
  vividlyGrey: "#E8E8EB",
  99: "#FDFBFF",
  95: "#F2F0F4",
  90: "#E3E2E6",
  80: "#C7C6CA",
  70: "#ACABAF",
  60: "#909094",
  50: "#77777A",
  40: "#5E5E61",
  30: "#46474A",
  20: "#2F3034",
  10: "#1A1B1F",
};

export const neutralVariant = {
  bright: "#EAE7EC",
  99: "#F9FDFF",
  95: "#EAF2F7",
  90: "#DCE4E8",
  80: "#C0C8CC",
  70: "#A4ACB1",
  60: "#8A9296",
  50: "#70787D",
  40: "#586064",
  30: "#40484C",
  20: "#2A3135",
  10: "#151D20",
};

export const primary = {
  bright: "#00CCFD",
  99: "#F9FDFF",
  95: "#DDF4FF",
  90: "#B6EAFF",
  80: "#51D5FF",
  70: "#00BAE8",
  60: "#009DC5",
  50: "#0081A2",
  40: "#006782",
  30: "#004D62",
  20: "#003545",
  10: "#001F2A",
};

export const secondary = {
  bright: "#D67DFF",
  99: "#FCFCFC",
  95: "#FFEAFF",
  90: "#F9D8FF",
  80: "#EBB2FF",
  70: "#DD89FF",
  60: "#C36BEC",
  50: "#A750D0",
  40: "#8B34B4",
  30: "#70129A",
  20: "#500074",
  10: "#310049",
};

export const tertiary = {
  bright: "#FF66FF",
  99: "#FCFCFC",
  95: "#FFEBFA",
  90: "#FFD7F9",
  80: "#FFA9FA",
  70: "#FF72FF",
  60: "#E64FE8",
  50: "#C72FCB",
  40: "#A700AD",
  30: "#7F0086",
  20: "#5A005F",
  10: "#37003B",
};

export const warning = {
  99: "#FCFCFC",
  95: "#FFEDE5",
  90: "#FFDBCB",
  80: "#FFB592",
  70: "#FF8C51",
  60: "#F16613",
  50: "#CB4F00",
  40: "#A33E00",
  30: "#7C2D00",
  20: "#581E00",
  10: "#360F00",
};

export const error = {
  99: "#FFEDE9",
  95: "#FFDAD4",
  90: "#FFB4A9",
  80: "#FF897A",
  70: "#FF5449",
  60: "#DD3730",
  50: "#BA1B1B",
  40: "#930006",
  30: "#680003",
  20: "#410001",
};

export const success = {
  99: "#F5FFEB",
  95: "#C8FFB8",
  90: "#AEF49F",
  80: "#94D786",
  70: "#79BB6D",
  60: "#5FA055",
  50: "#46853E",
  40: "#2D6B28",
  30: "#115211",
  20: "#003A00",
  10: "#002200",
};

export const gradient1Colors = [
  "#F7F003",
  "#00B4FF",
  "#AA80FF",
  "#FF66FF",
  "#FF6FB2",
  "#FF8110",
  "#FBB20A",
];

export const miscellaneousColors = {
  warningYellow: "#FDD246",
  disabledGrey: "#1F1F1F",
  aiLightLavender: "#E6E6FA",
  aiDeepLavender: "#533E84",
  popoverGray: "#F0EDF1",
  tableFooterGray: "#F6F2F7",
};

// Graph colors - WIP
export const graphColors = {
  purple: "#8B85FF",
  lightRed: "#FF7A7A",
  pink: "#EA62A1",
  darkGrey: "#49484c",
  white: "#FFFFFF",
  blue: "#2C99E8",
  lightBlue: "#6B8BFF",
  green: "#005F59",
  lightGreen: "#5ED1B1",
  transparent: "#FFFFFF00",
};

export const dataVisualizationGuide = {
  dataPink: "#EA336F",
  dataPurple: "#7B3DE2",
  dataBlue: "#2C99E8",
  dataTeal: "#005F59",
  dataGreen: "#AAC600",
  dataRed: "#C50101",
  dataBlack: "#1A1B1F",
};
